import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import { createStore } from 'vuex'
import { createI18n } from 'vue-i18n'

// global
import 'lodash'
import dayjs from 'dayjs'
import axios from 'axios'
window.dayjs = dayjs
window.axios = axios

// languages
import messages from './messages.js'
const i18n = createI18n({
  locale: 'ja',
  messages: messages,
})

// store
import store from './store.js'
const vuex = createStore(store)

// hover
import 'hover.css'

// mixin
import mixin from './mixin.js'

const app = createApp(App)
app.mixin(mixin)
app.use(router)
app.use(i18n)
app.use(vuex)
app.mount('#app')
