<template>
  <transition name="fade">
    <div
      v-if="item"
      class="popup overlay marker-item"
    >
      <div class="wrapper">
        <div class="page">
          <header>
            <h1><img :src="getI18nSrc('logo.svg')" /></h1>
          </header>
          <main>
            <div class="main-image">
              <img
                v-if="item.image"
                :src="item.image"
              />
              <video
                v-else-if="item.video"
                :src="item.video"
                controls
                autoplay
                muted
              ></video>
            </div>
            <h2 v-html="item.titleLang"></h2>
            <div
              class="description"
              v-html="item.descriptionLang"
            ></div>
            <p
              v-if="item.siteLink"
              class="go-site"
            >
              <a
                :href="item.siteLink"
                target="_blank"
                ><img :src="getI18nSrc('button_go_site.svg')"
              /></a>
            </p>
          </main>
          <footer>
            <template
              v-for="button in buttons"
              :key="button"
            >
              <a
                v-if="item[button]"
                :href="item[button]"
                target="_blank"
                >{{ $t('marker.' + button) }}</a
              >
            </template>
          </footer>
          <div
            class="close"
            @click="close"
          >
            <span class="material-icons">highlight_off</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['item'],
  emits: ['update:item'],
  data: () => {
    return {
      buttons: ['buttonDetail', 'buttonCreate'],
    }
  },
  computed: {
    items() {
      return this.$store.state.items
    },
  },
  watch: {
    item() {
      if (this.item) {
        // set anchor event (tour-popupへのリンクはポップアップを開く)
        setTimeout(() => {
          const anchors = document.querySelectorAll('.marker-item .description a')
          for (let i = 0; i < anchors.length; i++) {
            anchors[i].onclick = () => {
              if (anchors[i].href.includes('tour-popup')) {
                this.openPopup(anchors[i].href)
                return false
              }
            }
          }
        }, 100)
      }
    },
  },
  methods: {
    // ポップアップを開く
    openPopup(href) {
      this.close()
      // get popupId from href (ex. /tour-popup/[popupId])
      const popupId = href.split('tour-popup/')[1].replace('/', '')
      this.$store.commit('setPopupId', popupId)
    },
    close() {
      this.$emit('update:item', null)
      // コンプリート
      const filteredItems = _.filter(this.items, 'isAddList')
      if (!_.find(filteredItems, ['found', false])) {
        this.$store.commit('setCompleted', true)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
main {
  .main-image {
    margin-bottom: 30px;
    img {
      width: 100%;
      height: auto;
    }
    video {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
  }
  h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .description {
    margin-bottom: 30px;
    :deep(p) {
      margin-bottom: 20px;
    }
  }
  .go-site {
    text-align: center;
    img {
      @include imgSizeWidth(260px);
    }
  }
}
footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: 3px solid #000;
  padding: 20px 0 0;
  a {
    margin: 10px;
    text-decoration: none;
    color: #000;
    background-color: #faee00;
    padding: 10px 20px;
    border-radius: 5px;
    min-width: 120px;
    text-align: center;
  }
}
</style>
