import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import Tour from './views/Tour.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'タッチ・デ・カンチ（バーチャルツアー）｜漫湖水鳥・湿地センター' },
    component: Home,
  },
  {
    path: '/en',
    name: 'En',
    meta: { title: 'Touch and search : A virtual tour site that senses living things' },
    component: Home,
  },
  {
    path: '/tour',
    name: 'Tour',
    meta: { title: 'タッチ・デ・カンチ（バーチャルツアー）｜漫湖水鳥・湿地センター' },
    component: Tour,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.afterEach((to) => {
  document.title = to.meta.title
})
export default router
