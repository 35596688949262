<template>
  <transition name="fade">
    <div
      v-show="dialog"
      id="popup"
      class="popup overlay"
    >
      <div class="wrapper">
        <div class="page">
          <header>
            <h1><img :src="getI18nSrc('logo.svg')" /></h1>
          </header>
          <main>
            <div
              v-if="popup.image"
              class="main-image"
            >
              <img :src="popup.image" />
            </div>
            <h2>{{ popup.titleLang }}</h2>
            <div
              class="description"
              v-html="popup.descriptionLang"
            ></div>
          </main>
          <div
            class="close"
            @click="close"
          >
            <span class="material-icons">highlight_off</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: () => {
    return {
      popup: {},
    }
  },
  computed: {
    id() {
      return this.$store.state.popupId
    },
    dialog() {
      return this.id && _.find(this.$store.state.popups, ['id', this.id]) ? true : false
    },
  },
  watch: {
    // get popup content by id (delay for animation)
    id() {
      if (this.id) {
        this.popup = _.find(this.$store.state.popups, ['id', this.id]) || {}
      } else {
        setTimeout(() => {
          this.popup = {}
        }, 500)
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('setPopupId', '')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
#popup {
  main {
    .main-image {
      margin-bottom: 30px;
      img {
        width: 100%;
        height: auto;
      }
    }
    h2 {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .description {
      margin-bottom: 30px;
      :deep(p) {
        margin-bottom: 20px;
      }
      :deep(strong) {
        font-weight: bold;
      }
    }
  }
}
</style>
