<template>
  <div :class="{ map: true, visible: miniMap }">
    <img
      class="bg"
      src="@/assets/minimap.svg"
    />
    <img
      class="radar"
      src="@/assets/radar.svg"
      :style="radarStyle"
    />
    <div
      class="close"
      @click="close"
    >
      <span class="material-icons">highlight_off</span>
    </div>
  </div>
</template>

<script>
import PANOS from '@/panos.json'
export default {
  name: 'Minimap',
  props: ['miniMap'],
  emits: ['update:miniMap'],
  computed: {
    panoId() {
      return this.$store.state.panoId
    },
    pov() {
      return this.$store.state.pov
    },
    selectedPano() {
      return _.find(PANOS, ['id', this.panoId]) || PANOS[0]
    },
    radarStyle() {
      const angle = this.pov.heading - 50
      const coeff = window.innerHeight > 480 ? 1 : 200 / 300
      return {
        top: this.selectedPano.rador.top * coeff + 'px',
        left: this.selectedPano.rador.left * coeff + 'px',
        transform: 'rotate(' + angle + 'deg)',
      }
    },
  },
  methods: {
    close() {
      this.$emit('update:miniMap', false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
.map {
  @include absolute($top: 20px, $right: -400px, $z: 2);
  transition: right 0.5s;
  &.visible {
    right: 20px;
  }
  .bg {
    @include imgSizeWidth(200px);
  }
  .radar {
    @include imgSizeWidth(66.6px);
    position: absolute;
  }
  .close {
    @include absolute($top: -16px, $left: calc(-16px));
    span {
      font-size: 64px;
    }
  }
}
@media screen and (min-height: 480px) {
  .map {
    .bg {
      @include imgSizeWidth(300px);
    }
    .radar {
      @include imgSizeWidth(100px);
    }
  }
}
</style>
