<template>
  <MarkerItem v-model:item="item" />
  <MarkerInfo v-model:info="info" />
</template>

<script>
import PANOS from '@/panos.json'
import MarkerItem from '@/components/TourMarkerItem.vue'
import MarkerInfo from '@/components/TourMarkerInfo.vue'
let markers = []
export default {
  components: { MarkerItem, MarkerInfo },
  props: ['pano'],
  data: () => {
    return {
      item: null,
      info: null,
    }
  },
  computed: {
    panoId() {
      return this.$store.state.panoId
    },
    items: {
      get() {
        return this.$store.state.items
      },
      set(newItems) {
        this.$store.commit('setItems', newItems)
      },
    },
    infos() {
      return this.$store.state.infos
    },
    selectedPano() {
      return _.find(PANOS, ['id', this.panoId]) || PANOS[0]
    },
    center() {
      return this.selectedPano.center
    },
    iconCoeff() {
      return this.$store.state.iconCoeff
    },
    _renew() {
      return this.pano && this.infos.length && this.items.length ? this.panoId + this.$i18n.locale : 0
    },
  },
  watch: {
    iconCoeff() {
      this.renewMarkers()
    },
    _renew() {
      this.removeMarkers()
      setTimeout(() => {
        this.addMarkers()
      }, 500)
    },
  },
  async mounted() {},
  methods: {
    // マーカー更新
    renewMarkers() {
      _.forEach(markers, (marker) => {
        if (marker.detail) {
          marker.setMap(null)
          marker.setIcon(this.getMarkerIcon(marker.detail, marker.type))
          marker.setMap(this.pano)
        }
      })
    },
    // 全マーカー削除
    removeMarkers() {
      if (markers.length) {
        _.forEach(markers, (marker) => {
          marker.setMap(null)
        })
        markers = []
      }
    },
    // 全マーカー追加
    addMarkers() {
      _.forEach(['items', 'infos'], (type) => {
        _.forEach(this[type], (item, index) => {
          if (item.panoId === this.panoId) {
            this.addMarker(type, index)
          }
        })
      })
    },
    // 各マーカー追加
    addMarker(type, index) {
      const item = this[type][index]
      const icon = this.getMarkerIcon(item, type)
      const marker = new google.maps.Marker({
        position: { lat: item.lat, lng: item.lng },
        icon: icon,
        map: this.pano,
      })
      this.addMarkerEvent(marker, type, index)
      markers.push(_.assign(marker, { detail: item, type: type }))
    },
    // マーカーイベント付与
    addMarkerEvent(marker, type, index) {
      if (type === 'items') {
        // items
        const icon = this.getMarkerIcon(this.items[index], type)
        const hoverClassName = 'hover-' + this.items[index].hover
        marker.addListener('click', () => {
          const items = _.cloneDeep(this.items) // 要毎回取得
          const img = document.querySelector('img[src^="' + icon.url + '"]') // 要毎回取得
          if (img.classList.contains(hoverClassName)) {
            // pc
            this.item = items[index]
          } else {
            // mobile
            img.parentElement.classList.add(hoverClassName)
            setTimeout(() => {
              img.parentElement.classList.remove(hoverClassName)
              this.item = items[index]
            }, 500)
          }
          items[index].found = true
          this.items = items
        })
        marker.addListener('mouseover', () => {
          const img = document.querySelector('img[src^="' + icon.url + '"]')
          // 見切れ不具合対策（markerではなく外のdivを使用）
          img.parentElement.classList.add(hoverClassName)
          // img.classList.add(hoverClassName)
        })
        marker.addListener('mouseout', () => {
          const img = document.querySelector('img[src^="' + icon.url + '"]')
          // 見切れ不具合対策（markerではなく外のdivを使用）
          img.parentElement.classList.remove(hoverClassName)
          // img.classList.remove(hoverClassName)
        })
      } else {
        // infos
        marker.addListener('click', () => {
          this.info = this.infos[index]
        })
      }
    },
    // マーカーアイコン取得
    getMarkerIcon(item, type) {
      const scaledSize = {
        width: item.iconScaledSizeWidth * this.iconCoeff,
        height: item.iconScaledSizeHeight * this.iconCoeff,
      }
      const defaultIconUrl = type === 'items' ? 'shadow.png' : 'marker_info.png'
      const iconUrl = item.iconUrlLang || require('@/assets/' + defaultIconUrl)
      return {
        url: iconUrl + '?id=' + item.id + '&type=' + type,
        scaledSize: new google.maps.Size(scaledSize.width, scaledSize.height),
        anchor: new google.maps.Point(scaledSize.width / 2, scaledSize.height / 2 + item.iconAnchor * this.iconCoeff),
      }
    },
  },
}
</script>

<style>
.hover {
  display: inline-block;
  vertical-align: middle;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
}
.hover-float {
  transform: translateY(-8px);
}
.hover-grow {
  transform: scale(1.2);
}
.hover-forword {
  transform: translateX(8px);
}
</style>
