<template>
  <div
    id="home"
    :class="['page', $i18n.locale]"
  >
    <HomeHeader />
    <HomeMain />
  </div>
</template>

<script>
import HomeHeader from '@/views/HomeHeader.vue'
import HomeMain from '@/views/HomeMain.vue'
export default {
  components: { HomeHeader, HomeMain },
  watch: {
    // ルート変更時に言語を切り替える
    $route: {
      handler() {
        if (this.$route.path === '/en') {
          this.$i18n.locale = 'en'
        } else {
          this.$i18n.locale = 'ja'
        }
      },
      immediate: true,
    },
  },
}
</script>

<!-- <style lang="scss">
</style> -->
