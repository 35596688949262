<template>
  <div :class="['tour', $i18n.locale]">
    <h1 class="logo">
      <a :href="isJa() ? '/' : '/en'"><img :src="getI18nSrc('logo.svg')" /></a>
    </h1>
    <div id="panorama"></div>
    <Opening />
    <Arrows :pano="pano" />
    <Markers :pano="pano" />
    <Control :pano="pano" />
    <Popup />
    <Register />
  </div>
</template>

<script>
import Opening from '@/components/TourOpening.vue'
import Arrows from '@/components/TourArrows.vue'
import Markers from '@/components/TourMarkers.vue'
import Control from '@/components/TourControl.vue'
import Register from '@/components/TourRegister.vue'
import Popup from '@/components/TourPopup.vue'
import PANOS from '@/panos.json'
export default {
  components: { Opening, Arrows, Markers, Control, Register, Popup },
  data: () => {
    return {
      panos: Object.freeze(_.cloneDeep(PANOS)),
      pano: null,
      markerTimeout: 0,
    }
  },
  computed: {
    panoId() {
      return this.$store.state.panoId
    },
    pov() {
      return this.$store.state.pov
    },
    selectedPano() {
      return _.find(this.panos, ['id', this.panoId]) || this.panos[0]
    },
    items() {
      return this.$store.state.items
    }, // for addMarkerClass()
  },
  watch: {
    panoId(newId, oldId) {
      // 移動
      if (!_.isEmpty(this.pano)) {
        this.pano.setPosition(this.selectedPano.center)
        this.pano.setZoom(1)
        this.changeHeading(newId, oldId)
      }
    },
    pov() {
      this.renewIconCoeff()
    },
    items() {
      if (this.items.length) {
        setTimeout(() => {
          this.addMarkerClass()
        }, 1000)
      }
    },
  },
  mounted() {
    // 初期化
    setTimeout(() => {
      this.initialize()
    }, 1000)
  },
  methods: {
    renewIconCoeff() {
      // マーカーアイコン係数
      const coeff = this.getIconCoeff()
      if (this.$store.state.iconCoeff !== coeff) {
        this.$store.commit('setIconCoeff', coeff)
      }
    },
    initialize() {
      // 初期化
      const minSize = _.min([window.innerWidth, window.innerHeight])
      const panoContainer = document.getElementById('panorama')
      this.pano = new google.maps.StreetViewPanorama(
        panoContainer,
        _.assign(this.getPanoOptions(), {
          position: this.selectedPano.center,
          pov: { heading: this.selectedPano.heading, pitch: -20 },
          zoom: minSize < 860 ? 2 : 1,
        })
      )
      this.$store.commit('setPov', this.pano.getPov())
      this.addMarkerClass()
      this.pano.addListener('pov_changed', () => {
        this.$store.commit('setPov', this.pano.getPov())
        this.addMarkerClass()
      })
    },
    // 視点変更
    changeHeading(newId, oldId) {
      setTimeout(() => {
        const heading = oldId > newId ? (this.selectedPano.heading + 180) % 360 : this.selectedPano.heading
        this.pano.setPov({ heading: heading, pitch: -20 })
      }, 100)
    },
    // 各マーカーにクラス付与
    addMarkerClass() {
      clearTimeout(this.markerTimeout)
      this.markerTimeout = setTimeout(() => {
        this.renewIconCoeff()
        const markers = document.querySelectorAll('#panorama img[src*="type="]')
        const areas = document.querySelectorAll('#panorama img[src*="transparent"]')
        _.forEach(markers, (marker, index) => {
          // normal class
          marker.classList.add('marker')
          if (areas.length && areas[index]) {
            areas[index].classList.add('area')
          }
          // type class
          _.forEach(['arrow', 'item', 'info'], (type) => {
            if (_.includes(marker.src, 'type=' + type)) {
              marker.classList.add('marker-' + type)
              if (areas.length && areas[index]) {
                areas[index].classList.add('area-' + type)
              }
            }
          })
          // hover class
          if (_.includes(marker.src, 'type=item')) {
            // 見切れ不具合対策（markerではなく外のdivを使用）
            marker.parentElement.classList.add('hover')
          }
        })
      }, 500)
    },
    // マーカーアイコン係数取得
    getIconCoeff() {
      const degree = 103.7587 * 0.5051 ** this.pov.zoom
      const width = document.getElementById('panorama').clientWidth
      const height = document.getElementById('panorama').clientHeight
      const coeffWidth = 1 - (20 - width / degree) / 20
      const coeffHeight = (height - 300) / 300 / 10
      return _.round(coeffWidth + coeffHeight, 3)
    },
    // ストリートビューオプション
    getPanoOptions() {
      return {
        clickToGo: false,
        linksControl: false,
        panControl: false,
        enableCloseButton: false,
        zoomControl: false,
        fullscreenControl: false,
        addressControl: false,
        disableDoubleClickZoom: true,
        scrollwheel: true,
        motionTracking: false,
        disableDefaultUI: true,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';

.tour {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

#panorama {
  width: 100%;
  height: 100%;

  :deep(div[aria-label='Map'] div div div) {
    overflow: visible !important;
  }

  :deep(img.marker-item),
  :deep(img.marker-info),
  :deep(img.area-item),
  :deep(img.area-info) {
    cursor: url('../assets/cursor_search2.png'), pointer !important;
  }
}

h1 {
  @include fixed($top: 30px, $left: 30px, $z: 2);

  img {
    width: 200px;
    max-width: min(30vw, 30vh);
  }
}
</style>

<style lang="scss">
.gmnoprint,
.gm-style-cc {
  bottom: 80px !important;
}

img[alt='Google'] {
  position: relative !important;
  top: -80px !important;
}
</style>
