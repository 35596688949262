<template>
  <transition name="fade">
    <div
      v-if="book"
      id="list"
      class="popup overlay"
    >
      <div class="wrapper">
        <div class="page">
          <header>
            <h1><img :src="getI18nSrc('logo_list.svg')" /></h1>
            <div v-html="getHtml('list')"></div>
          </header>
          <main>
            <ul>
              <template
                v-for="item in items"
                :key="item.id"
              >
                <BookItem :item="item" />
              </template>
            </ul>
          </main>
          <div
            class="close"
            @click="close"
          >
            <span class="material-icons">highlight_off</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BookItem from '@/components/TourControlBookItem.vue'
export default {
  components: { BookItem },
  props: ['book'],
  emits: ['update:book'],
  computed: {
    items() {
      return _.filter(this.$store.state.items, ['isAddList', true])
    },
  },
  methods: {
    close() {
      this.$emit('update:book', false)
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/settings.scss';
#list {
  .wrapper {
    width: 90%;
    max-width: 1200px;
  }
  .page {
    background-color: $bg-color;
    header {
      @include row();
      align-items: center;
      padding: 30px 0;
      margin: 0 auto;
      h1 {
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
        border-bottom: none;
        img {
          @include imgSizeWidth($width: 280px);
        }
      }
      p {
        border-bottom: 3px solid #000;
        padding: 0 10px 16px;
        margin-left: 0;
      }
    }
    main {
      margin: 40px auto;
      ul {
        @include row();
        margin: 0 0 30px;
      }
      .back {
        text-align: center;
      }
    }
  }
  @media screen and (min-width: 900px) {
    .page {
      header {
        flex-wrap: nowrap;
        h1 {
          img {
            @include imgSizeWidth($width: 340px);
          }
        }
        p {
          padding: 0 30px 16px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
