export default {
  en: {
    // 共通
    title: 'Touch and search : A virtual tour site that senses living things',
    // ホーム
    home: {
      atFirst: 'Explanation',
      try: 'Start',
      about: 'What is “Touch and search”?',
      start: 'Start tour',
    },
    // 開始
    opening: {
      new: 'Start again',
      continue: 'Continue',
    },
    // コントロール
    control: {
      exit: 'Exit',
      route: 'Route',
      zoomin: 'ZoomIn',
      zoomout: 'ZoomOut',
      minimap: 'Map',
      howto: 'HowTo',
      book: 'List',
      star: 'WellDone',
      lang: '日本語',
    },
    // マーカー
    marker: {
      buttonDetail: 'More information',
      buttonCreate: 'Crafts',
      scroll: 'Scroll through!',
    },
    // 登録
    register: {
      name: 'Your name',
      register: 'Register name',
    },
  },
  ja: {
    // 共通
    title: 'タッチ・デ・カンチ｜生きものを感知するバーチャルツアーサイト',
    // ホーム
    home: {
      atFirst: 'はじめに',
      try: 'やってみる',
      about: 'タッチ・デ・カンチとは？',
      start: 'ツアーをはじめる',
    },
    // 開始
    opening: {
      new: 'はじめから',
      continue: '続きから',
    },
    // コントロール
    control: {
      exit: '出口',
      route: '順路',
      zoomin: '拡大',
      zoomout: '縮小',
      minimap: '地図',
      howto: '使い方',
      book: '一覧',
      star: 'スター',
      lang: 'English',
    },
    // マーカー
    marker: {
      buttonDetail: 'くわしく見る',
      buttonCreate: 'つくって遊ぶ',
      scroll: 'スクロールしてみてね！',
    },
    // 登録
    register: {
      name: 'お名前',
      register: '登録する',
    },
  },
}
