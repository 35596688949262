<template>
  <transition name="fade">
    <div
      v-if="info"
      class="popup overlay"
    >
      <div class="wrapper">
        <div class="page">
          <header>
            <h1>
              <img :src="getI18nSrc('logo.svg')" />
              <div class="right">
                <span class="title">\ {{ info.titleLang }} /</span>
                <span class="desc">{{ $t('marker.scroll') }}</span>
              </div>
            </h1>
          </header>
          <main>
            <p
              v-for="image in images"
              :key="image"
            >
              <img
                :src="image"
                alt=""
              />
            </p>
          </main>
          <div
            class="close"
            @click="close"
          >
            <span class="material-icons">highlight_off</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['info'],
  emits: ['update:info'],
  data: () => {
    return {}
  },
  computed: {
    images() {
      return _.compact(
        _.map(_.range(5), (index) => {
          const imageJa = this.info['image' + index]
          const imageEn = this.info['image' + index + '_en']
          return this.$i18n.locale === 'en' && imageEn ? imageEn : imageJa
        })
      )
    },
  },
  methods: {
    close() {
      this.$emit('update:info', null)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
.wrapper {
  max-width: 900px;
}
header {
  padding: 0;
  margin-bottom: 50px;
  h1 {
    img {
      margin-bottom: 10px;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      top: 30px;
      letter-spacing: 0.2em;
    }
    .title {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 20px;
    }
  }
}
main {
  p {
    margin-bottom: 60px;
    img {
      width: 100%;
    }
  }
}
.en {
  header {
    h1 {
      .right {
        letter-spacing: 0;
      }
    }
  }
}
@media screen and (min-width: 760px) {
  header {
    background-image: url(@/assets/scroll.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 100px auto;
    padding: 0 120px 50px 0;
    margin-bottom: 20px;
    h1 {
      @include row;
      align-items: stretch;
      padding: 0 5% 0 0;
      img {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: 900px) {
  header {
    h1 {
      .title {
        font-size: 36px;
      }
    }
  }
}
</style>
