<template>
  <transition name="fade">
    <div
      v-if="star"
      id="star"
      class="popup overlay"
    >
      <div class="wrapper">
        <div class="page">
          <header>
            <h1><img :src="getI18nSrc('logo_star.svg')" /></h1>
          </header>
          <main>
            <div
              class="desc"
              v-html="getHtml('star')"
            ></div>
            <ul>
              <li
                v-for="(eachStar, index) in stars"
                :key="index"
              >
                <img src="@/assets/star.svg" />
                <time>{{ getDate(eachStar.date) }}</time>
                <div class="name">
                  <span class="first">{{ eachStar.name }}</span>
                  <span
                    v-if="isJa()"
                    class="suffix"
                    >さん</span
                  >
                </div>
              </li>
            </ul>
          </main>
          <div
            class="close"
            @click="close"
          >
            <span class="material-icons">highlight_off</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: ['star'],
  emits: ['update:star'],
  computed: {
    stars() {
      return this.$store.state.stars
    },
  },
  methods: {
    getDate(dateStr) {
      return dayjs(dateStr).format('YYYY.MM.DD')
    },
    close() {
      this.$emit('update:star', false)
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/settings.scss';
#star {
  .page {
    background-color: #f4f18f;
  }
  header {
    margin: 20px 0;
    background-image: url(../assets/star.svg), url(../assets/star.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: left 20px, right 20px;
    background-size: 40px auto, 40px auto;
    h1 {
      border: none;
      position: relative;
      padding-top: 10px;
      img {
        @include imgSizeWidth(200px);
      }
      &::before {
        border-bottom: 4px solid #000;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 30px;
        width: 100%;
      }
    }
  }
  main {
    margin: 0 0 30px;
    p {
      margin-bottom: 30px;
      border-bottom: 4px solid #000;
      padding: 0 0 20px;
    }
    li {
      margin-bottom: 20px;
      position: relative;
      padding-left: 20px;
      img {
        @include imgSizeWidth(45px);
        @include absolute($top: 0, $left: 0);
        z-index: 1;
      }
      time {
        @include roundedCorner($size: 30px);
        font-size: 16px;
        padding: 0 20px 0 30px;
        display: inline-block;
        background-color: black;
        color: #fff;
        position: relative;
        top: 6px;
      }
      .name {
        @include roundedCorner($size: 50px);
        line-height: 44px;
        border: 3px solid #000;
        padding: 0 20px 0 30px;
        background-color: #fff;
        font-size: 14px;
        white-space: nowrap;
        .first {
          font-weight: bold;
        }
        .suffix {
          font-size: 0.7em;
        }
      }
    }
  }
  @media screen and (min-width: 700px) {
    header {
      margin: 20px 0;
      background-position: left top, right top;
      background-size: 70px auto, 70px auto;
      h1 {
        img {
          @include imgSizeWidth(300px);
        }
        &::before {
          bottom: 50px;
        }
      }
    }
    main {
      margin: 0 0 30px;
      p {
        padding: 10px 0 30px;
      }
      li {
        padding-left: 20px;
        time {
          font-size: 20px;
        }
        .name {
          font-size: 24px;
          .family {
            margin: 0 10px;
          }
        }
      }
    }
  }
}
</style>
