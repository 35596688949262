// mixin
export default {
  methods: {
    isJa() {
      return this.$i18n.locale === 'ja'
    },
    isEn() {
      return this.$i18n.locale === 'en'
    },
    // 画像パスを言語に応じて変更する
    getI18nSrc(src) {
      const ext = src.split('.').pop()
      const i18nSrc = this.isJa() ? src : src.replace(`.${ext}`, `_en.${ext}`)
      return require(`@/assets/${i18nSrc}`)
    },
    // 指定ページのHTMLを取得する
    getHtml(pageId) {
      const content = _.find(this.$store.state.contents, ['id', pageId])
      return content ? content.htmlLang : ''
    },
  },
}
