<template>
  <div class="control">
    <button @click="toggleRoute"><span class="material-icons">directions</span>{{ $t('control.route') }}</button>
    <button @click="exit"><span class="material-icons">logout</span>{{ $t('control.exit') }}</button>
    <span class="spacer"></span>
    <button
      :class="{ zoomin: true, disabled: currentZoom >= 3 }"
      @click="zoomIn"
    >
      <span class="material-icons">add_circle_outline</span>{{ $t('control.zoomin') }}
    </button>
    <button
      :class="{ zoomout: true, disabled: currentZoom <= 0.65 }"
      @click="zoomOut"
    >
      <span class="material-icons">remove_circle_outline</span>{{ $t('control.zoomout') }}
    </button>
    <button @click="toggleMiniMap"><span class="material-icons">map</span>{{ $t('control.minimap') }}</button>
    <button @click="toggleHowto"><span class="material-icons">help_outline</span>{{ $t('control.howto') }}</button>
    <button @click="toggleBook"><span class="material-icons">menu_book</span>{{ $t('control.book') }}</button>
    <button @click="toggleStar">
      <span class="material-icons">face_retouching_natural</span>{{ $t('control.star') }}
    </button>
    <button @click="toggleLang"><span class="material-icons">translate</span>{{ $t('control.lang') }}</button>
  </div>
  <Howto v-model:howto="howto" />
  <Book v-model:book="book" />
  <MiniMap v-model:miniMap="miniMap" />
  <Route v-model:route="route" />
  <Star v-model:star="star" />
</template>

<script>
import Howto from '@/components/TourControlHowto.vue'
import Book from '@/components/TourControlBook.vue'
import MiniMap from '@/components/TourControlMiniMap.vue'
import Route from '@/components/TourControlRoute.vue'
import Star from '@/components/TourControlStar.vue'
export default {
  components: { Howto, Book, MiniMap, Route, Star },
  props: ['pano'],
  data: () => {
    return {
      currentZoom: 1,
      howto: false,
      miniMap: true,
      book: false,
      route: true,
      star: false,
    }
  },
  watch: {},
  mounted() {},
  methods: {
    zoomIn() {
      this.currentZoom = this.pano.getZoom()
      if (this.currentZoom < 3) {
        this.pano.setZoom(this.currentZoom + 0.5)
        this.currentZoom = this.pano.getZoom()
        this.$store.commit('setPov', _.cloneDeep(this.pano.getPov()))
      }
    },
    zoomOut() {
      this.currentZoom = this.pano.getZoom()
      if (this.currentZoom > 0.65) {
        this.pano.setZoom(this.currentZoom - 0.5)
        this.currentZoom = this.pano.getZoom()
        this.$store.commit('setPov', _.cloneDeep(this.pano.getPov()))
      }
    },
    toggleMiniMap() {
      this.miniMap = !this.miniMap
    },
    toggleHowto() {
      this.howto = !this.howto
    },
    toggleBook() {
      this.book = !this.book
    },
    toggleRoute() {
      this.route = !this.route
    },
    toggleStar() {
      this.star = !this.star
    },
    toggleLang() {
      this.$i18n.locale = this.isJa() ? 'en' : 'ja'
    },
    exit() {
      location.href = 'https://www.manko-mizudori.net/'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
.control {
  @include fixed($bottom: 0, $left: 0, $z: 3); // route +1
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 80px;
  padding: 0 20px;
}
button {
  @include center;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  &.disabled {
    cursor: auto;
    opacity: 0.4;
  }
  &.zoomin,
  &.zoomout {
    display: none;
  }
}
.material-icons {
  font-size: 28px;
  margin: 0 10px 2px;
}
@media screen and (min-width: 500px) {
  button {
    &.zoomin,
    &.zoomout {
      display: flex;
    }
  }
}
@media screen and (min-width: 640px) {
  button {
    font-size: 16px;
  }
  .material-icons {
    font-size: 40px;
    margin: 0 20px;
  }
}
</style>
