<template>
  <main id="main">
    <section>
      <h2>
        <span class="sub">{{ $t('home.atFirst') }}</span>
        <span class="main">{{ $t('home.about') }}</span>
      </h2>
      <div v-html="getHtml('home')"></div>
      <p class="start-image">
        <RoundedButton
          to="/tour"
          color="#6c9ac0"
          >{{ $t('home.start') }}</RoundedButton
        >
      </p>
      <div class="mark"><img :src="getI18nSrc('logo.svg')" /></div>
    </section>
  </main>
</template>

<script>
import RoundedButton from '@/components/RoundedButton.vue'
export default {
  components: { RoundedButton },
}
</script>

<style lang="scss">
@import '@/scss/settings.scss';
#main {
  background-color: $bg-color;
  overflow: hidden;
  border-top: 5px solid #000;
  section {
    @include width(90%, 1000px);
    @include shadow(calc(5px + calc(100vw / 200)), 30px);
    position: relative;
    margin: 150px auto;
    padding: 50px calc(10px + 6%);
  }
  h2 {
    @include title;
    text-align: center;
    width: calc(240px + 8vw);
    margin: 0 auto 30px;
    position: relative;
    top: -75px;
    .main {
      @include center;
      @include shadow(6px, 23px);
      height: 46px;
      position: relative;
    }
    .sub {
      @include absolute($top: -32px, $left: 50%, $x: -50%);
      display: inline-block;
      height: 50px;
      border-radius: 25px;
      background-color: #000;
      color: #fff;
      padding: 5px 30px;
    }
  }
  h3 {
    @include title;
    margin: 0 auto 35px auto;
    text-align: center;
    line-height: 1.4;
    span {
      display: inline-block;
      padding: 0 30px 15px;
      border-bottom: 3px solid #000;
    }
  }
  h2 + h3 {
    margin-top: -50px;
  }
  p {
    margin-bottom: 50px;
  }
  .reference {
    font-size: 0.9em;
    margin: -20px 0 50px 0;
    line-height: 1.5;
  }
  .start-image {
    @include center;
    @include sizedBackImage(50%, 270px, 1.7778, url(../assets/main-image.jpg));
    margin: 0 auto 50px;
  }
  .mark {
    @include absolute($bottom: calc(5vw - 120px), $right: -5vw);
    img {
      @include imgSizeWidth(300px, 60vw, none);
    }
  }
}
</style>
