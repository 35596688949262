<template>
  <a
    v-if="href"
    :href="href"
    :style="{ backgroundColor: color }"
    ><slot
  /></a>
  <router-link
    v-else-if="to"
    :to="to"
    :style="{ backgroundColor: color }"
    ><slot
  /></router-link>
  <button
    v-else
    :style="{ backgroundColor: color }"
    @click="click"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'RoundedButton',
  props: ['color', 'href', 'to', 'click'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
a,
button {
  display: inline-block;
  color: #fff;
  height: 46px;
  line-height: 46px;
  width: 180px;
  text-decoration: none;
  text-align: center;
  border-radius: 25px;
  position: relative;
  margin-bottom: 20px;
  background-color: red; // JS
  &::after {
    @include fullscreen(3px);
    content: '';
    display: block;
    border: 2px solid #fff;
    border-radius: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
