<template>
  <transition name="fade">
    <div
      v-show="dialog"
      id="opening"
      class="popup overlay"
    >
      <div class="wrapper">
        <div class="page">
          <header>
            <h1><img :src="getI18nSrc('logo.svg')" /></h1>
          </header>
          <main>
            <div v-html="getHtml('opening')"></div>
            <p class="button">
              <RoundedButton
                color="#A4A36A"
                :click="takeOver"
                >{{ $t('opening.continue') }}</RoundedButton
              >
            </p>
            <p class="button">
              <RoundedButton
                color="#6C99BF"
                :click="fromBegining"
                >{{ $t('opening.new') }}</RoundedButton
              >
            </p>
          </main>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RoundedButton from '@/components/RoundedButton.vue'

export default {
  components: { RoundedButton },
  data: () => {
    return {
      dialog: false,
      done: false,
    }
  },
  computed: {
    items() {
      return this.$store.state.items
    },
    newItems() {
      return this.$store.state.newItems
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        if (this.items.length && !this.done) {
          this.done = true
          if (_.find(this.items, ['found', true])) {
            this.dialog = true
          }
        }
      },
    },
  },
  mounted() {
    // Item一覧
  },
  methods: {
    fromBegining() {
      this.$store.commit('setItems', _.cloneDeep(this.newItems))
      this.$store.commit('setResistered', false)
      this.dialog = false
    },
    takeOver() {
      this.dialog = false
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/settings.scss';
#opening {
  main {
    h2 {
      border-bottom: 3px solid #000;
      text-align: center;
      font-size: 24px;
      padding: 0 0 14px;
      margin: 50px 0 30px;
      font-weight: bold;
    }
    p {
      margin-bottom: 30px;
    }
    .button {
      text-align: center;
    }
  }
}
</style>
