<template>
  <router-view />
</template>

<script>
import dataLoader from '@/dataLoader'
export default {
  // 多言語化
  watch: {
    '$i18n.locale': function () {
      this.switchLang()
    },
  },
  // データ取得
  async created() {
    // contents
    const contents = await dataLoader.loadContents()
    this.$store.commit('setContents', this.fetchContents(contents))
    // popups
    const popups = await dataLoader.loadPopups()
    this.$store.commit('setPopups', this.fetchPopups(popups))
    // Infos
    const infos = await dataLoader.loadInfos()
    this.$store.commit('setInfos', this.fetchItems(infos))
    // Items
    const newItems = await dataLoader.loadItems()
    const oldItems = dataLoader.getLocalStorageItems()
    const items = dataLoader.restoreFound(newItems, oldItems)
    this.$store.commit('setNewItems', this.fetchItems(newItems))
    this.$store.commit('setItems', this.fetchItems(items))
    // stars
    const stars = await dataLoader.loadStars()
    this.$store.commit('setStars', stars)
    // コンプリート登録済み確認
    let resistered = localStorage.getItem('resistered') ? true : false // 'done' or ''
    this.$store.commit('setResistered', resistered)
    // 確認
    // console.log(_.cloneDeep(this.$store.state.contents))
    // console.log(_.cloneDeep(this.$store.state.popups))
    // console.log(_.cloneDeep(this.$store.state.infos))
    // console.log(_.cloneDeep(this.$store.state.items))
  },
  methods: {
    switchLang() {
      // title
      document.title = this.$t('title')
      // contents
      const contents = this.$store.state.contents
      this.$store.commit('setContents', this.fetchContents(contents))
      // popups
      const popups = this.$store.state.popups
      this.$store.commit('setPopups', this.fetchContents(popups))
      // infos
      const infos = this.$store.state.infos
      this.$store.commit('setInfos', this.fetchItems(infos))
      // items
      const items = this.$store.state.items
      this.$store.commit('setItems', this.fetchItems(items))
    },
    // fetch contents && popups
    fetchContents(contents) {
      return contents.map((content) => ({
        ...content,
        titleLang: this.isEn() && content.title_en ? content.title_en : content.title,
        htmlLang: this.isEn() && content.html_en ? content.html_en : content.html,
      }))
    },
    // fetch popups
    fetchPopups(popups) {
      return popups.map((item) => ({
        ...item,
        titleLang: this.isEn() && item.title_en ? item.title_en : item.title,
        descriptionLang: this.isEn() && item.description_en ? item.description_en : item.description,
      }))
    },
    // fetch items & infos
    fetchItems(items) {
      return items.map((item) => ({
        ...item,
        titleLang: this.isEn() && item.title_en ? item.title_en : item.title,
        nameLang: this.isEn() && item.name_en ? item.name_en : item.name,
        descriptionLang: this.isEn() && item.description_en ? item.description_en : item.description,
        iconUrlLang: this.isEn() && item.iconUrl_en ? item.iconUrl_en : item.iconUrl,
        thumbnailShadowLang: this.isEn() && item.thumbnailShadow_en ? item.thumbnailShadow_en : item.thumbnailShadow,
        thumbnailFoundLang: this.isEn() && item.thumbnailFound_en ? item.thumbnailFound_en : item.thumbnailFound,
      }))
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/common.scss';
</style>
