// store
import PANOS from '@/panos.json'

export default {
  state() {
    return {
      panoId: 1, // 現在地
      pov: { heading: PANOS[0].heading, pitch: 0, zoom: 1 }, // 視点
      contents: [], // コンテンツ
      popups: [], // ポップアップコンテンツ
      newItems: [], // 生き物リスト（工場出荷時）
      items: [], // 生き物リスト
      infos: [], // 解説版リスト
      stars: [], // コンプリートリスト
      clearDialog: false, // データ引き継ぎダイアログ
      completed: false, // コンプリート完了
      resistered: false, // コンプリート登録済み
      iconCoeff: 1, // iconサイズ調整用係数
      popupId: '', // ポップアップ表示中のID
    }
  },
  mutations: {
    setPanoId(state, num) {
      state.panoId = num
    },
    setPov(state, obj) {
      state.pov = obj
    },
    setContents(state, arr) {
      state.contents = arr
    },
    setPopups(state, arr) {
      state.popups = arr
    },
    setNewItems(state, arr) {
      state.newItems = arr
    },
    setItems(state, arr) {
      state.items = arr
      localStorage.setItem('items', JSON.stringify(arr))
    },
    setInfos(state, arr) {
      state.infos = arr
    },
    setStars(state, arr) {
      state.stars = arr
    },
    setClearDialog(state, bool) {
      state.clearDialog = bool
    },
    setCompleted(state, bool) {
      state.completed = bool
    },
    setResistered(state, bool) {
      state.resistered = bool
      localStorage.setItem('resistered', bool ? 'done' : '')
    },
    setIconCoeff(state, num) {
      state.iconCoeff = num
    },
    setPopupId(state, str) {
      state.popupId = str
    },
  },
}
