<template>
  <header>
    <div class="switch-lang">
      <router-link :to="isJa() ? '/en' : '/'">
        <span class="material-icons">translate</span>
        <span class="label">{{ isJa() ? 'English' : '日本語' }}</span>
      </router-link>
    </div>
    <div class="inner">
      <h1><img :src="getI18nSrc('logo.svg')" /></h1>
      <RoundedButton
        href="#main"
        color="#A4A36A"
        >{{ $t('home.atFirst') }}</RoundedButton
      >
      <RoundedButton
        to="/tour"
        color="#6C99BF"
        >{{ $t('home.try') }}</RoundedButton
      >
    </div>
  </header>
</template>

<script>
import RoundedButton from '@/components/RoundedButton.vue'
export default {
  components: { RoundedButton },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
header {
  position: relative;
  background-image: url(../assets/bg_top.jpg);
  background-size: auto 100%;
  background-position: 100% center;
  height: 90vh;
  animation-name: slide;
  animation-duration: 100s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  .switch-lang {
    z-index: 9999;
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: #222;
    padding: 5px;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      line-height: 1.3;
      color: white;
      border: 1px solid #fff;
      padding: 3px 0 0;
      width: 62px;
      height: 62px;
    }
    .material-icons {
      font-size: 1.8rem;
    }
    .label {
      font-size: 0.7rem;
    }
  }
  .inner {
    @include center;
    @include fullscreen;
  }
  h1 {
    width: 100%;
    text-align: center;
    img {
      @include imgSizeWidth(50%, 500px, 300px);
    }
    margin-bottom: 20px;
  }
}

@keyframes slide {
  0% {
    background-position: 0% center;
  }
  50% {
    background-position: 100% center;
  }
  100% {
    background-position: 0% center;
  }
}
</style>
