<template>
  <div></div>
</template>

<script>
import PANOS from '@/panos.json'
let arrows = []
export default {
  props: ['pano'],
  computed: {
    panoId() {
      return this.$store.state.panoId
    },
    selectedPano() {
      return _.find(PANOS, ['id', this.panoId]) || PANOS[0]
    },
    center() {
      return this.selectedPano.center
    },
    _renew() {
      return this.panoId && !_.isEmpty(this.pano) ? this.panoId : null
    },
  },
  watch: {
    _renew() {
      setTimeout(() => {
        this.removeArrows()
        this.addArrows()
      }, 500)
    },
  },
  mounted() {},
  methods: {
    removeArrows() {
      if (arrows.length) {
        _.forEach(arrows, (arrow) => {
          arrow.setMap(null)
        })
        arrows = []
      }
    },
    addArrows() {
      _.forEach(this.selectedPano.arrows, (arrow) => {
        const replace = {
          lat: arrow.distance * Math.cos((arrow.heading * Math.PI) / 180) * 0.000009,
          lng: arrow.distance * Math.sin((arrow.heading * Math.PI) / 180) * 0.000011,
        }
        const src = require('@/assets/arrow' + arrow.icon + '.svg')
        const marker = new google.maps.Marker({
          position: { lat: this.center.lat + replace.lat, lng: this.center.lng + replace.lng },
          icon: {
            url: src + '?type=arrow',
            scaledSize: new google.maps.Size(300, 300),
            anchor: new google.maps.Point(0, 0),
          },
          map: this.pano,
        })
        marker.addListener('click', () => {
          this.$store.commit('setPanoId', arrow.to)
        })
        arrows.push(marker)
      })
    },
  },
}
</script>

<style></style>
