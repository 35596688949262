// data loader

export default {
  // contentデータ取得
  async loadContents() {
    const res = await axios.get('https://www.manko-mizudori.net/wp-json/wp/v2/tour-content').catch((err) => {
      console.log(err)
    })
    return res && res.data ? this.fetchContents(res.data) : []
  },
  // popupデータ取得
  async loadPopups() {
    const res = await axios
      .get('https://www.manko-mizudori.net/wp-json/wp/v2/tour-popup/?per_page=100')
      .catch((err) => {
        console.log(err)
      })
    return res && res.data ? this.fetchPopups(res.data) : []
  },
  // starsデータ取得
  async loadStars() {
    let stars = []
    const res = await axios
      .get('https://www.manko-mizudori.net/wp-json/wp/v2/comments/?post=7660&per_page=100')
      .catch((err) => {
        console.log(err)
      })
    if (_.has(res, 'data')) {
      stars = _.map(res.data, (star) => {
        return {
          name: star.author_name.replace('　', ' '),
          date: star.date,
        }
      })
    }
    return stars
  },
  // itemsデータ取得
  async loadItems() {
    const res = await axios
      .get('https://www.manko-mizudori.net/wp-json/wp/v2/tour-items/?per_page=100')
      .catch((err) => {
        console.log(err)
      })
    return res && res.data ? this.fetchItems(res.data) : []
  },
  // infosデータ取得
  async loadInfos() {
    const res = await axios
      .get('https://www.manko-mizudori.net/wp-json/wp/v2/tour-infos/?per_page=100')
      .catch((err) => {
        console.log(err)
      })
    return res && res.data ? this.fetchItems(res.data) : []
  },
  // fetch contents & popups
  fetchContents(data) {
    return _.map(data, (item) => {
      return {
        id: item.slug,
        title: item.title.rendered,
        html: this.addTargetBlank(item.content.rendered),
        html_en: this.addTargetBlank(item.acf.content_en) || '',
      }
    })
  },
  // fetchPopups
  fetchPopups(data) {
    return _.map(data, (item) => {
      return {
        ...item.acf,
        id: item.slug,
        title: item.title.rendered,
        description: this.addTargetBlank(item.acf.description),
        description_en: this.addTargetBlank(item.acf.description_en),
      }
    })
  },
  // fetch items & infos
  fetchItems(data) {
    const items = _.map(data, (item) => {
      const newItem = {
        ...item.acf,
        id: item.id,
        title: item.title.rendered,
        order: item.menu_order,
        found: false,
        description: this.addTargetBlank(item.acf.description),
        description_en: this.addTargetBlank(item.acf.description_en),
      }
      // 数値文字列を数値に変換
      _.forEach(newItem, (value, key) => {
        newItem[key] = this.stringToNumber(value)
      })
      return newItem
    })
    return _.sortBy(items, ['order'])
  },
  // aタグにtarget="_blank"を追加
  addTargetBlank(html) {
    const regex = /<a href="https?:\/\/.+?".*?>/g
    return html
      ? html.replace(regex, (match) => {
          return match.replace('>', ' target="_blank">')
        })
      : ''
  },
  // 数値文字列を数値に変換
  stringToNumber(value) {
    if (value === '' || value === null) {
      return ''
    } else if (_.isBoolean(value)) {
      return value
    } else if (_.isNaN(_.toNumber(value))) {
      return value
    } else {
      return _.toNumber(value)
    }
  },
  // localstorageデータ取得
  getLocalStorageItems() {
    const itemsStr = localStorage.getItem('items')
    let items = []
    if (itemsStr) {
      try {
        items = JSON.parse(itemsStr)
      } catch (err) {
        console.log('localstorage error')
      }
    }
    return items
  },
  // Found情報の復元
  restoreFound(newItems, oldItems) {
    const mergedItems = _.cloneDeep(newItems)
    _.forEach(mergedItems, (mergedItem) => {
      const oldItem = _.find(oldItems, (oldItem) => {
        return oldItem.id === mergedItem.id
      })
      if (oldItem) {
        mergedItem.found = oldItem.found
      }
    })
    return mergedItems
  },
}
