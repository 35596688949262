<template>
  <li
    :class="{ found: item && item.found ? true : false }"
    @click.stop.prevent="linkTo"
  >
    <span class="thumbnail"><img :src="src" /></span>
    <span class="name">{{ name }}</span>
  </li>
</template>

<script>
export default {
  name: 'BookItem',
  props: ['item'],
  computed: {
    src() {
      const srcShadow = this.item.thumbnailShadowLang || require('@/assets/shadow.png')
      return _.get(this.item, 'found') ? this.item.thumbnailFoundLang : srcShadow
    },
    name() {
      return _.get(this.item, 'found') ? this.item.nameLang : '？？？？？？？？'
    },
  },
  methods: {
    linkTo() {
      if (_.get(this.item, 'found')) {
        window.open(this.item.siteLink, '_blank')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
li {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  height: 80px;
  font-size: 20px;
  &.found {
    cursor: pointer;
  }
  .thumbnail {
    @include absolute($top: -15px, $left: -2px);
    display: inline-block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    border: 3px solid #000000;
    background-color: #fff;
    padding: 3px;
    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
      // @include imgSizeWidth($width: 80px);
    }
  }
  .name {
    @include roundedCorner(50px);
    display: block;
    background-color: #fff;
    border: 3px solid #000;
    padding: 0 30px 0 80px;
    white-space: nowrap;
  }
}
@media screen and (min-width: 680px) {
  li {
    width: 48%;
    span {
      padding: 0 30px 0 100px;
    }
  }
}
</style>
