<template>
  <transition name="fade">
    <div
      v-show="dialog"
      id="register"
      class="popup overlay"
    >
      <div class="wrapper">
        <div class="page">
          <header>
            <h1><img :src="getI18nSrc('logo_star.svg')" /></h1>
          </header>
          <main>
            <div v-html="getHtml('register')"></div>
            <form onsubmit="return false">
              <p class="input-box">
                <input
                  id="name"
                  type="text"
                  name="name"
                  :placeholder="$t('register.name')"
                  maxlength="13"
                  required
                />
              </p>
              <p class="button">
                <RoundedButton
                  color="#6C99BF"
                  :click="send"
                  >{{ $t('register.register') }}</RoundedButton
                >
              </p>
            </form>
          </main>
          <div
            class="close"
            @click="close"
          >
            <span class="material-icons">highlight_off</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RoundedButton from '@/components/RoundedButton.vue'

export default {
  components: { RoundedButton },
  data: () => {
    return {}
  },
  computed: {
    items() {
      return this.$store.state.items
    },
    resistered() {
      return this.$store.state.resistered
    },
    dialog() {
      return this.$store.state.completed && !this.resistered ? true : false
    },
  },
  methods: {
    send() {
      const inputName = document.getElementById('name')
      if (inputName.value) {
        axios
          .post('https://www.manko-mizudori.net/wp-json/wp/v2/comments/', {
            post: 7660,
            author_name: inputName.value,
            author_email: '',
            content: '-',
          })
          .catch((error) => {
            console.log(error)
          })
        this.$store.commit('setResistered', true)
      }
    },
    close() {
      this.$store.commit('setResistered', true)
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/settings.scss';
#register {
  .page {
    background-color: #f4f18f;
  }
  header {
    margin: 20px 0;
    background-image: url(../assets/star.svg), url(../assets/star.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: left 20px, right 20px;
    background-size: 40px auto, 40px auto;
    h1 {
      border: none;
      position: relative;
      padding-top: 10px;
      img {
        @include imgSizeWidth(200px);
      }
      &::before {
        border-bottom: 4px solid #000;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 30px;
        width: 100%;
      }
    }
  }
  main {
    h2 {
      border-bottom: 3px solid #000;
      text-align: center;
      font-size: 24px;
      padding: 0 0 14px;
      margin: 50px 0 30px;
      font-weight: bold;
    }
    p {
      margin-bottom: 30px;
    }
    small {
      font-size: 0.9em;
      opacity: 0.8;
    }
    .input-box {
      text-align: center;
    }
    input {
      background-color: lightgoldenrodyellow;
      border-radius: 10px;
      padding: 10px 20px;
      font-size: 24px;
      text-align: left;
      max-width: 100%;
    }
    .button {
      text-align: center;
    }
  }
  @media screen and (min-width: 700px) {
    header {
      margin: 20px 0;
      background-position: left top, right top;
      background-size: 70px auto, 70px auto;
      h1 {
        img {
          @include imgSizeWidth(300px);
        }
        &::before {
          bottom: 50px;
        }
      }
    }
  }
}
</style>
