<template>
  <transition name="fade">
    <div
      v-show="howto"
      id="howto"
      class="popup overlay"
    >
      <div class="wrapper">
        <div class="page">
          <header>
            <h1><img :src="getI18nSrc('logo.svg')" /></h1>
          </header>
          <main v-html="getHtml('howto')"></main>
          <div
            class="close"
            @click="close"
          >
            <span class="material-icons">highlight_off</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['howto'],
  emits: ['update:howto'],
  methods: {
    close() {
      this.$emit('update:howto', false)
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/settings.scss';
#howto {
  main {
    h2 {
      border-bottom: 3px solid #000;
      text-align: center;
      font-size: 24px;
      padding: 0 0 14px;
      margin: 50px 0 30px;
      font-weight: bold;
    }
    h3 {
      margin-bottom: 14px;
      font-size: 20px;
      font-weight: 500;
    }
    p {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      span:first-child {
        margin-right: 16px;
        font-size: 40px;
      }
    }
  }
}
</style>
