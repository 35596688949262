<template>
  <transition name="slide-up">
    <div
      v-if="route"
      class="route"
    >
      <Carousel :items-to-show="itemToShow">
        <Slide
          v-for="pano in panos"
          :key="pano.id"
          :class="{ current: panoId === pano.id }"
          @mousedown="down"
          @mouseup="move(pano.id)"
        >
          <span class="number">{{ pano.id }}</span>
          <img :src="require('@/assets/route' + String(pano.id).padStart(2, '0') + '.jpg')" />
        </Slide>
      </Carousel>
    </div>
  </transition>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import PANOS from '@/panos.json'
export default {
  name: 'Route',
  components: { Carousel, Slide },
  props: ['route'],
  emits: ['update:route'],
  data: () => {
    return {
      bodyWidth: 0,
      panos: PANOS,
      downTime: 0,
    }
  },
  computed: {
    panoId() {
      return this.$store.state.panoId
    },
    imgWidth() {
      return this.bodyWidth > 480 ? 160 : 120
    },
    itemToShow() {
      return _.floor(this.bodyWidth / (this.imgWidth + 10))
    },
  },
  mounted() {
    this.bodyWidth = document.body.clientWidth
    window.addEventListener('resize', () => {
      this.bodyWidth = document.body.clientWidth
    })
    setTimeout(() => {
      this.$emit('update:route', false)
    }, 0)
  },
  methods: {
    down() {
      this.downTime = dayjs().valueOf()
    },
    move(id) {
      // 要検討（drag関係のeventを使うべきでは？）
      const upTime = dayjs().valueOf()
      if (upTime - this.downTime < 300) {
        this.$store.commit('setPanoId', id)
      }
    },
    close() {
      this.$emit('update:route', false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/settings.scss';
.route {
  @include absolute($bottom: 80px, $left: 0, $z: 2);
  position: fixed;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px 0 0;
}
.carousel {
  :deep(.carousel__viewport) {
    overflow: visible;
  }
  .carousel__slide {
    position: relative;
    .number {
      @include center;
      @include absolute($top: -6px, $left: 50%);
      @include circle(36px);
      transform: translateX(-68px);
      background-color: #fff;
      font-weight: bold;
      border: 3px solid #000;
      font-size: 20px;
    }
    &.current img {
      border: 5px solid #fff;
    }
  }
  img {
    @include imgSizeWidth(120px);
    pointer-events: none;
  }
}
@media screen and (min-width: 480px) {
  .carousel {
    .carousel__slide {
      .number {
        transform: translateX(-90px);
      }
    }
    img {
      @include imgSizeWidth(160px);
    }
  }
}
</style>
